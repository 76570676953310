import Vue from 'vue';
import Notifications from 'vue-notification';
import ProductCarousels from '../components/ProductCarousels.vue';

if (process.env.VUE_APP_DEV_TOOLS) {
  Vue.config.devtools = true;
}
Vue.use(Notifications);

const args = document.getElementById('vproductCarousels').getAttribute('data-args');

new Vue({
  render: (h) => h(ProductCarousels, { props: { args } })
}).$mount('#vproductCarousels');

<template>
  <div class="product_carousel"
    v-if="productCarouselItems && productCarouselItems.length > 0">
    <div class="product_carousel_header">
      <div class="product_carousel_header__box">
        <div class="product_carousel_header__title">
          {{ groupTitle }}
        </div>
        <div class="product_carousel_header__tabs"
          v-if="productCarousels && productCarousels.length > 1">
          <div class="wc-button wc-button--secondary wc-button--small"
            :class="{ 'selected': tabIndex === index }"
            v-for="(item, index) in productCarousels"
            :key="index" @click="switchCarouselTab(index)">
            <span class="label">{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div class="product_carousel_actions">
        <a class="product_carousel_actions__cta wc-button wc-button--link wc-button--small"
        v-if="shopAllLink" :href="shopAllLink">
          <span class="label">Shop all</span>
        </a>
        <div class="product_carousel_actions__nav"
        v-if="pageCount > 1">
          <button class="wc-button wc-button--secondary wc-button--small wc-button--small__icon"
            @click="goToSlide(slideIndex - 1)" :disabled="slideIndex === 0">
            <span class="label"><i class="fa-solid fa-chevron-left"></i></span>
          </button>
          <button class="wc-button wc-button--secondary wc-button--small wc-button--small__icon"
            @click="goToSlide(slideIndex + 1)" :disabled="slideIndex === pageCount - 1">
            <span class="label"><i class="fa-solid fa-chevron-right"></i></span>
          </button>
        </div>
      </div>
    </div>
    <div class="product_carousel_body" :class="carouselRef">
      <Carousel :ref="carouselRef" :navigationEnabled="false" :paginationEnabled="false"
        @pageChange="updateActiveSlide"
        :scrollPerPage="true" :perPageCustom="[[480, 2], [996, 4], [1320, 6]]">
        <Slide v-for="(item, index) in productCarouselItems" :key="index">
            <div v-html="item"></div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'ProductCarousel',
  props: {
    productCarousel: Object,
  },
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      tabIndex: 0,
      slideIndex: 0,
      pageCount: 0,
    };
  },
  computed: {
    groupTitle() {
      return this.productCarousel.group_title;
    },
    productCarousels() {
      return this.productCarousel.product_carousels;
    },
    productCarouselItems() {
      return this.productCarousels[this.tabIndex].items;
    },
    shopAllLink() {
      return this.productCarousels[this.tabIndex].link;
    },
    carouselRef() {
      // Generate a unique ref for each carousel
      const uid = Math.random().toString(36).substr(2, 9);
      return `carousel_${uid}`;
    }
  },
  methods: {
    goToSlide(index) {
      this.slideIndex = index;
      this.pageCount = this.$refs[this.carouselRef].pageCount;
      this.$refs[this.carouselRef].goToPage(index);
    },
    switchCarouselTab(index) {
      this.tabIndex = index;
      Vue.nextTick(() => {
        this.pageCount = this.$refs[this.carouselRef].pageCount;
        window.nx.hydrateCards(`.${this.carouselRef}`);
      });
    },
    updateActiveSlide(index) {
      this.slideIndex = index;
    }
  },
  mounted() {
    this.pageCount = this.$refs[this.carouselRef].pageCount;
  }
};
</script>

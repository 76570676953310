<template>
    <div>
        <div class="product_carousel_container" v-for="productCarousel in productCarouselGroups"
            :key="productCarousel.group_title">
            <ProductCarousel :productCarousel="productCarousel" />
        </div>
    </div>
</template>

<script>
import ProductCarousel from './ProductCarousel.vue';

export default {
  name: 'ProductCarousels',
  props: {
    args: String,
  },
  components: {
    ProductCarousel,
  },
  computed: {
    arguments() {
      return JSON.parse(this.args);
    },
    productCarouselGroups() {
      return this.arguments;
    },
  },
};
</script>
